import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CLEAR_VALIDATION_ERRORS,
  LOGOUT_REQUEST,
  GET_PROFILE_DATA,
  SET_CURRENT_CODE,
  SET_CURRENT_PHONE,
  ADD_RESEND,
  SET_PHONE, 
  RESET_RESEND_COUNT,
  TWO_FACTOR_ERROR,
  SET_CURRENT_BLOODBANK,
  SET_TO_ALL_BLOODBANKS,
  SET_PASSWORDS,
} from './actionTypes';
import { createAction } from 'redux-actions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { saveState } from '../../services/localStorage';
import redirect from '../../services/redirect';
import { addPhoneRequest, get } from '../../services/fetch';
import routes from '../../constants/routes';
import * as dateFns from "date-fns";
import {
  duplicatePasswordsError,
  getForgotPasswordValidationMessage,
  getLoginValidationMessage,
  getNewPasswordValidationMessage,
  passwordValidationError,
} from "../../services/validation";
import authCodes, { loginFailureReason } from '../../constants/authCodes';
import { sendErrorLog, sendSigninAttemptLog } from "../logging/actions";
import {toast} from "react-toastify";
import { getUserIp } from '../../services/helpers';

export const loginRequest = createAction(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);
export const forgotPasswordRequest = createAction(FORGOT_PASSWORD_REQUEST);
export const forgotPasswordFailure = createAction(FORGOT_PASSWORD_FAILURE);
export const changePasswordRequest = createAction(CHANGE_PASSWORD_REQUEST);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailure = createAction(CHANGE_PASSWORD_FAILURE);
export const clearValidationErrors = createAction(CLEAR_VALIDATION_ERRORS);
export const userLogoutRequest = createAction(LOGOUT_REQUEST);
export const getProfileData = createAction(GET_PROFILE_DATA);
export const setCurrentCode = createAction(SET_CURRENT_CODE);
export const setCurrentPhone = createAction(SET_CURRENT_PHONE);
export const addResend = createAction(ADD_RESEND);
export const setPhone = createAction(SET_PHONE);
export const resetResendCount = createAction(RESET_RESEND_COUNT);
export const twoFactorError = createAction(TWO_FACTOR_ERROR);
export const setCurrentBloodbank = createAction(SET_CURRENT_BLOODBANK);
export const setToAllBloodbanks = createAction(SET_TO_ALL_BLOODBANKS);
export const setPasswords = createAction(SET_PASSWORDS);


export const handleSetCurrentBloodbank = (bloodbank) => (dispatch) => {
  dispatch(setCurrentBloodbank(bloodbank));
  const dateFormat = "yyyy-MM-dd";
  const formattedDate = dateFns.format(new Date(), dateFormat);
  redirect(`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`);
};

export const clearAuthValidationErrors = () => (dispatch) => {
  dispatch(clearValidationErrors());
};

const redirectToCurrentDayPage = () => {
  const dateFormat = "yyyy-MM-dd";
  const formattedDate = dateFns.format(new Date(), dateFormat);
  redirect(`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`);
};

export const getIdToken = (redirect=false) => (dispatch) => {
  return firebase.auth().currentUser.getIdTokenResult(true)
    .then(res => {
      dispatch(loginSuccess(res));
      if (redirect) {
        redirectToCurrentDayPage();
      }
      return res.token;
    })
    .catch(err => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(loginFailure(getLoginValidationMessage(err.code)))
    });
};

const isTokenRefreshNeeded = (idToken, expTimestamp) => {
  if (idToken && expTimestamp) {  
    const expiresIn = expTimestamp - Date.now();
    if (expiresIn <= 0) {
      return true;
    }
    return false;
  }
  return true;
};

export const getValidIdToken = () => (dispatch, getState) => {
  const { idToken, expTimestamp } = getState()?.authReducer;

  if (isTokenRefreshNeeded(idToken, expTimestamp)) {
    return dispatch(getIdToken()); 
  } else {    
    return idToken;
  }
};

export const login = ({email, password}) =>async  (dispatch) => {
  dispatch(loginRequest());
  
  let ip = await getUserIp();

  firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {

      const { uid } = firebase.auth().currentUser.toJSON();
      const docRef = firebase.firestore().collection('admins').doc(uid);
      docRef.get().then(async doc => {
        const userDoc = doc.data();
        if (userDoc.access) {

          const {roleName, profileStorageRef, phone} = userDoc;

          const receivedBloodbanks = userDoc.access;
          const preparedBloodbanks = [];
          for (let index = 0; index < receivedBloodbanks.length; index++) {
            const { bloodbankId, name=null } = receivedBloodbanks[index];
            if (name) {
              preparedBloodbanks.push({bloodbankId, bloodbankName: name});
            } else {
              const bloodbankRef = firebase.firestore().collection('bloodbanks').doc(bloodbankId);
                const doc = await bloodbankRef.get()
                const bloodbankDoc = doc.data();
                const bloodbankName = bloodbankDoc?.name;
                preparedBloodbanks.push({bloodbankId, bloodbankName});
            }
          }
          dispatch(setToAllBloodbanks(preparedBloodbanks));
          dispatch(setCurrentBloodbank(preparedBloodbanks[0]));

          dispatch(getProfileData({roleName, profileStorageRef, phone, uid}));
          if(phone){
            redirect(`${routes.TWO_FACTOR_AUTH_ROUTE}`);
            firebase.auth().useDeviceLanguage();
            const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('sign-in-button'), {
              'size':'invisible'
            });

            firebase.auth().signInWithPhoneNumber(`+${phone}`, recaptchaVerifier)
            .then((res) => {
              dispatch(setCurrentCode(res));
              recaptchaVerifier.clear()
            })
            .catch((err) => {
              if (err?.message?.includes('auth/too-many-requests') || err?.message?.includes('auth/too-many-attempts') || err?.message?.includes('auth/error-code:-39')) {
                dispatch(sendSigninAttemptLog({ email, status: 'failure', failureReason: loginFailureReason.TOO_MANY_REQUESTS, ip, type: 'login' }))
                return toast.error('You have reached your OTP confirmation limit for today. Please try again tomorrow.', { position: 'bottom-right', style: { padding: '15px 20px' } });
              }
              redirect(`${routes.LOGIN_ROUTE}`);
              dispatch(sendSigninAttemptLog({ email, status: 'failure', failureReason: loginFailureReason.TWO_FACTOR_ERROR, ip, type: 'login' }))
              dispatch(sendErrorLog(err.message, err.code));
              dispatch(twoFactorError())
              dispatch(sendSigninAttemptLog({email,status:'failure',ip,type:'login'}))
            })
          } else {
            dispatch(sendSigninAttemptLog({email,status:'success',ip,type:'login'}))
            dispatch(getIdToken(true));
          }
        } else {
          dispatch(sendSigninAttemptLog({ email, status: 'failure', failureReason: loginFailureReason.NOT_FOUND, ip, type: 'login' }))
          dispatch(sendErrorLog('User not found', authCodes.USER_NOT_FOUND));
          dispatch(loginFailure())
        }
      });
    })
    .catch(err => {
      dispatch(sendSigninAttemptLog({ email, status: 'failure', ip, failureReason: loginFailureReason.WRONG_CREDENTIALS, type: 'login' }))
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(loginFailure())
    });
};

export const confirmCode = (code) => async (dispatch, getState) => {
  let ip = await getUserIp();
  const user = firebase.auth().currentUser;
  const email = user ? user?.email : '';
  const { currentCode } = getState().authReducer;
  currentCode.confirm(code)
    .then(() => {
      dispatch(getIdToken(true));
      dispatch(sendSigninAttemptLog({ email, status: 'success', ip, type: 'login' }))
    })
    .catch((err) => {
      dispatch(sendSigninAttemptLog({ email, status: 'failure', failureReason: loginFailureReason.TWO_FACTOR_WRONG_CODE, ip, type: 'login' }))
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(twoFactorError());
    })
}

export const resendCode = () => (dispatch, getState) => {

  const { phone } = getState().authReducer;

  firebase.auth().useDeviceLanguage();


  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('resend-link'), {
    'size':'invisible'
  });

  firebase.auth().signInWithPhoneNumber(`+${phone}`, recaptchaVerifier)
  .then((res) => {
    dispatch(setCurrentCode(res));
    dispatch(addResend());
    redirect(`${routes.TWO_FACTOR_AUTH_CODE_SENT_ROUTE}`);
  })
  .catch((err) => {
    dispatch(sendErrorLog(err.message, err.code));
    dispatch(twoFactorError())
  })
}

export const changePhone = (phone) => (dispatch) => {
  firebase.auth().useDeviceLanguage();
  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('sign-in-button-change'), {
    'size':'invisible'
  });
  const provider = new firebase.auth.PhoneAuthProvider();
  provider.verifyPhoneNumber(`+${phone}`, recaptchaVerifier)
  .then((res) => {
    dispatch(setPhone(`+${phone}`));
    dispatch(setCurrentCode(res));
    redirect(`${routes.CHANGE_PHONE_ENTER_CODE}`);
  })
  .catch((err) => {
    if (err?.message?.includes('auth/too-many-requests') || err?.message?.includes('auth/too-many-attempts') || err?.message?.includes('auth/error-code:-39')) {
      return toast.error('You have reached your OTP confirmation limit for today. Please try again tomorrow.', { position: 'bottom-right', style: { padding: '15px 20px' } });
    }
    dispatch(sendErrorLog(err.message, err.code));
    dispatch(twoFactorError());
  })
}

export const confirmCodeMobile = (code) => async (dispatch, getState) => {
  const { currentCode: verificationId, userId, currentPhone } = getState().authReducer;
  const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, code)
  const user = firebase.auth().currentUser;
  try {
    await user.updatePhoneNumber(phoneCredential);
    dispatch(resetResendCount())
    await addPhoneRequest('/users/addPhone', {userId, phone: currentPhone});
    redirect(routes.CHANGE_PHONE_SUCCESS_ROUTE);
  } catch (err) {
    dispatch(sendErrorLog(err.message, err.code));
    dispatch(twoFactorError())
  }
}

export const resendCodeMobile = () => (dispatch, getState) => {

  const { phone } = getState().authReducer;

  firebase.auth().useDeviceLanguage();


  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('resend-link-mobile'), {
    'size':'invisible'
  });

  firebase.auth().signInWithPhoneNumber(`+${phone}`, recaptchaVerifier)
  .then((res) => {
    dispatch(setCurrentCode(res));
    dispatch(addResend());
    redirect(`${routes.TWO_FACTOR_AUTH_CODE_SENT_ROUTE}`);
  })
  .catch((err) => {
    dispatch(sendErrorLog(err.message, err.code));
    dispatch(twoFactorError())
  })
}

export const verifyAuth = () => (dispatch) => {
  firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      //TODO check token expiration time
      dispatch(logoutRequest());
    }
  })
};

export const logoutRequest = () => async (dispatch) => {
  let ip = await getUserIp();

  const user = firebase.auth().currentUser;
  if (user) {
    const email = user ? user?.email : '';
    dispatch(userLogoutRequest());
    firebase.auth().signOut()
      .then(() => {

        dispatch(sendSigninAttemptLog({ email, status: 'success', ip, type: 'logout' }))
        // saveState({});
      })
      .catch(err => {
        dispatch(sendErrorLog(err.message, err.code));
        dispatch(sendSigninAttemptLog({ email, status: 'failure', type: 'logout', ip, failureReason: err.message || '' }))
        dispatch(loginFailure());
      });
  }
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch(forgotPasswordRequest());
  firebase.auth().sendPasswordResetEmail(email)
    .then(() => redirect(routes.FORGOT_PASSWORD_EMAIL_SENT_ROUTE))
    .catch(err => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(forgotPasswordFailure(getForgotPasswordValidationMessage(err.code)));
    });
};

export const resetPasswordRequest = ({verificationCode, newPassword}) => async (dispatch) => {
  await dispatch(forgotPasswordRequest());
  const error = passwordValidationError(newPassword);
  if (error) {
    dispatch(forgotPasswordFailure(error));
    return;
  }
  firebase.auth().confirmPasswordReset(verificationCode, newPassword)
    .then(() => redirect(routes.LOGIN_ROUTE))
    .catch(err => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(forgotPasswordFailure(getForgotPasswordValidationMessage(err.code)));
    });
};

const updatePassword = (user, newPassword) => (dispatch) => {
  user.updatePassword(newPassword)
    .then(() => {
      dispatch(changePasswordSuccess());
      const dateFormat = "yyyy-MM-dd";
      const formattedDate = dateFns.format(new Date(), dateFormat);
      redirect(`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`);
    })
    .catch(err => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(changePasswordFailure(getNewPasswordValidationMessage(err.code)));
    });
};


export const getCodeMobilePassword = (phone) => (dispatch) => {
  firebase.auth().useDeviceLanguage();
  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('sign-in-button-change-pass'), {
    'size': 'invisible'
  });

  const provider = new firebase.auth.PhoneAuthProvider();

  provider.verifyPhoneNumber(phone, recaptchaVerifier)
    .then((verificationId) => {
      dispatch(setPhone(phone));
      dispatch(setCurrentCode(verificationId));
      redirect(routes.CHANGE_PASSWORD_ENTER_CODE);
    }).catch((err) => {
      if (err?.message?.includes('auth/too-many-requests') || err?.message?.includes('auth/too-many-attempts') || err?.message?.includes('auth/error-code:-39')) {
        return toast.error('You have reached your OTP confirmation limit for today. Please try again tomorrow.', { position: 'bottom-right', style: { padding: '15px 20px' } });
      }
      dispatch(sendErrorLog(err.message, err.code));
    });
}

export const confirmCodeMobilePassword = (code) => (dispatch, getState) => {
  const { currentCode, passwords: {oldPassword, newPassword} } = getState().authReducer;

  const phoneCredential = firebase.auth.PhoneAuthProvider.credential(currentCode, code);

  firebase.auth().currentUser.reauthenticateWithCredential(phoneCredential)
    .then((userCredential) => {
      const validationError = duplicatePasswordsError(oldPassword, newPassword) || passwordValidationError(newPassword);
      if (validationError) {
        dispatch(changePasswordFailure({newPasswordError: validationError}))
      } else {
        dispatch(updatePassword(userCredential.user, newPassword))
      }
    }).catch((err) => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(twoFactorError())
    }).finally(() => {
      dispatch(setPasswords({oldPassword: null, newPassword: null}));
  });
}

export const changePassword = ({oldPassword, newPassword}) => (dispatch) => {
  dispatch(changePasswordRequest());
  const user = firebase.auth().currentUser;
  if (!user) {
    dispatch(changePasswordFailure("Invalid user"));
    redirect(routes.LOGIN_ROUTE);
  }
  if (user.phoneNumber) {
    dispatch(setPasswords({oldPassword, newPassword}));
    const validationError = duplicatePasswordsError(oldPassword, newPassword) || passwordValidationError(newPassword);
    if (validationError) {
      dispatch(changePasswordFailure({newPasswordError: validationError}))
    } else {
      dispatch(getCodeMobilePassword(user.phoneNumber, oldPassword, newPassword));
    }
    return;
  }
  const credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
  user.reauthenticateWithCredential(credentials)
    .then(() => {
      const validationError = duplicatePasswordsError(oldPassword, newPassword) || passwordValidationError(newPassword);
      if (validationError) {
        dispatch(changePasswordFailure({newPasswordError: validationError}))
      } else {
        dispatch(updatePassword(user, newPassword))
      }
    })
    .catch(err => {
      dispatch(sendErrorLog(err.message, err.code));
      dispatch(changePasswordFailure(getNewPasswordValidationMessage(err.code)));
    });
};